import { Auth0Client } from '@auth0/auth0-spa-js'

export const useAuthStore = defineStore('auth', {
  /**
   * @returns {{role: null, client: null | Promise<Auth0Client>, user: null, token: null, permissions:null}}
   */
  state: () => ({
    client: null,
    token: null,
    user: null,
    role: null,
    permissions: null,
  }),
  getters: {
    isLoaded() {
      return this.user !== null
    },
    isContractor() {
      return this.role && this.role.name === 'contractor'
    },
  },
  actions: {
    async getClient() {
      if (this.client === null) {
        console.log('setting up auth0 client...')
        const config = useRuntimeConfig()

        this.client = new Auth0Client({
          domain: config.public.auth0.domain,
          clientId: config.public.auth0.clientId,
          authorizationParams: {
            redirect_uri: config.public.auth0.redirectUri,
            audience: config.public.auth0.audience,
          },
          audience: config.public.auth0.audience,
        })

        console.log('...setting up auth0 client...')
      }

      return this.client
    },

    async getUser() {
      const config = useRuntimeConfig()
      const client = await this.client
      this.token = await client.getTokenSilently()
      const [user, role] = await Promise.all([
        client.getUser(),
        getRole(this.token, config.public.apiBaseUrl),
      ])
      this.user = user
      this.role = role

      updatePerson(config.public.apiBaseUrl, user.org_id, this.token)
      this.permissions = JSON.parse(atob(this.token.split('.')[1])).permissions
    },

    async logout() {
      await this.client.logout()
    },
  },
})

const getRole = async (token, apiBaseUrl) => {
  const response = await fetch(`${apiBaseUrl}/role`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response.json()
}
const updatePerson = async (apiBaseUrl, orgId, token) =>
  await $fetch(`${apiBaseUrl}/organizations/${orgId}/persons`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: { token: token },
  })
