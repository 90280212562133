import { default as indexe4XcNUzCNMMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/board/[id]/create-claim/index.vue?macro=true";
import { default as indexPVGgik4yw6Meta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/board/[id]/edit/[claim_id]/index.vue?macro=true";
import { default as indexEdD28A5T22Meta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/board/[id]/index.vue?macro=true";
import { default as indexGz9EspGcI1Meta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/claims/index.vue?macro=true";
import { default as indexiYcJmjHbfJMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/contractors/[id]/total-statuses/index.vue?macro=true";
import { default as indexT7iEvrgk5NMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/contractors/index.vue?macro=true";
import { default as indexhn6tkkDXUlMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/dashboard/index.vue?macro=true";
import { default as indexLvI0ZqsKZJMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/error-404/index.vue?macro=true";
import { default as indexU4xi4Spg5IMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/groups/index.vue?macro=true";
import { default as indexvENvwisLwLMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/index.vue?macro=true";
import { default as indexC9S3rlTconMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/insurance-companies/[id]/adjusters/index.vue?macro=true";
import { default as indexd4dsbMIfp3Meta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/insurance-companies/index.vue?macro=true";
import { default as indexkknnkb3GOGMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/login/index.vue?macro=true";
import { default as indexGX4TTBYfCkMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/notifications/index.vue?macro=true";
import { default as indexGtKSuhFh5FMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/signup/index.vue?macro=true";
import { default as index7iSGlcQUhLMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/something-went-wrong/index.vue?macro=true";
import { default as indexMQS45LvcvmMeta } from "/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/users/index.vue?macro=true";
export default [
  {
    name: indexe4XcNUzCNMMeta?.name ?? "board-id-create-claim",
    path: indexe4XcNUzCNMMeta?.path ?? "/board/:id()/create-claim",
    meta: indexe4XcNUzCNMMeta || {},
    alias: indexe4XcNUzCNMMeta?.alias || [],
    redirect: indexe4XcNUzCNMMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/board/[id]/create-claim/index.vue").then(m => m.default || m)
  },
  {
    name: indexPVGgik4yw6Meta?.name ?? "board-id-edit-claim_id",
    path: indexPVGgik4yw6Meta?.path ?? "/board/:id()/edit/:claim_id()",
    meta: indexPVGgik4yw6Meta || {},
    alias: indexPVGgik4yw6Meta?.alias || [],
    redirect: indexPVGgik4yw6Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/board/[id]/edit/[claim_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEdD28A5T22Meta?.name ?? "board-id",
    path: indexEdD28A5T22Meta?.path ?? "/board/:id()",
    meta: indexEdD28A5T22Meta || {},
    alias: indexEdD28A5T22Meta?.alias || [],
    redirect: indexEdD28A5T22Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/board/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGz9EspGcI1Meta?.name ?? "claims",
    path: indexGz9EspGcI1Meta?.path ?? "/claims",
    meta: indexGz9EspGcI1Meta || {},
    alias: indexGz9EspGcI1Meta?.alias || [],
    redirect: indexGz9EspGcI1Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/claims/index.vue").then(m => m.default || m)
  },
  {
    name: indexiYcJmjHbfJMeta?.name ?? "contractors-id-total-statuses",
    path: indexiYcJmjHbfJMeta?.path ?? "/contractors/:id()/total-statuses",
    meta: indexiYcJmjHbfJMeta || {},
    alias: indexiYcJmjHbfJMeta?.alias || [],
    redirect: indexiYcJmjHbfJMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/contractors/[id]/total-statuses/index.vue").then(m => m.default || m)
  },
  {
    name: indexT7iEvrgk5NMeta?.name ?? "contractors",
    path: indexT7iEvrgk5NMeta?.path ?? "/contractors",
    meta: indexT7iEvrgk5NMeta || {},
    alias: indexT7iEvrgk5NMeta?.alias || [],
    redirect: indexT7iEvrgk5NMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/contractors/index.vue").then(m => m.default || m)
  },
  {
    name: indexhn6tkkDXUlMeta?.name ?? "dashboard",
    path: indexhn6tkkDXUlMeta?.path ?? "/dashboard",
    meta: indexhn6tkkDXUlMeta || {},
    alias: indexhn6tkkDXUlMeta?.alias || [],
    redirect: indexhn6tkkDXUlMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexLvI0ZqsKZJMeta?.name ?? "error-404",
    path: indexLvI0ZqsKZJMeta?.path ?? "/error-404",
    meta: indexLvI0ZqsKZJMeta || {},
    alias: indexLvI0ZqsKZJMeta?.alias || [],
    redirect: indexLvI0ZqsKZJMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/error-404/index.vue").then(m => m.default || m)
  },
  {
    name: indexU4xi4Spg5IMeta?.name ?? "groups",
    path: indexU4xi4Spg5IMeta?.path ?? "/groups",
    meta: indexU4xi4Spg5IMeta || {},
    alias: indexU4xi4Spg5IMeta?.alias || [],
    redirect: indexU4xi4Spg5IMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexvENvwisLwLMeta?.name ?? "index",
    path: indexvENvwisLwLMeta?.path ?? "/",
    meta: indexvENvwisLwLMeta || {},
    alias: indexvENvwisLwLMeta?.alias || [],
    redirect: indexvENvwisLwLMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexC9S3rlTconMeta?.name ?? "insurance-companies-id-adjusters",
    path: indexC9S3rlTconMeta?.path ?? "/insurance-companies/:id()/adjusters",
    meta: indexC9S3rlTconMeta || {},
    alias: indexC9S3rlTconMeta?.alias || [],
    redirect: indexC9S3rlTconMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/insurance-companies/[id]/adjusters/index.vue").then(m => m.default || m)
  },
  {
    name: indexd4dsbMIfp3Meta?.name ?? "insurance-companies",
    path: indexd4dsbMIfp3Meta?.path ?? "/insurance-companies",
    meta: indexd4dsbMIfp3Meta || {},
    alias: indexd4dsbMIfp3Meta?.alias || [],
    redirect: indexd4dsbMIfp3Meta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/insurance-companies/index.vue").then(m => m.default || m)
  },
  {
    name: indexkknnkb3GOGMeta?.name ?? "login",
    path: indexkknnkb3GOGMeta?.path ?? "/login",
    meta: indexkknnkb3GOGMeta || {},
    alias: indexkknnkb3GOGMeta?.alias || [],
    redirect: indexkknnkb3GOGMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexGX4TTBYfCkMeta?.name ?? "notifications",
    path: indexGX4TTBYfCkMeta?.path ?? "/notifications",
    meta: indexGX4TTBYfCkMeta || {},
    alias: indexGX4TTBYfCkMeta?.alias || [],
    redirect: indexGX4TTBYfCkMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: indexGtKSuhFh5FMeta?.name ?? "signup",
    path: indexGtKSuhFh5FMeta?.path ?? "/signup",
    meta: indexGtKSuhFh5FMeta || {},
    alias: indexGtKSuhFh5FMeta?.alias || [],
    redirect: indexGtKSuhFh5FMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: index7iSGlcQUhLMeta?.name ?? "something-went-wrong",
    path: index7iSGlcQUhLMeta?.path ?? "/something-went-wrong",
    meta: index7iSGlcQUhLMeta || {},
    alias: index7iSGlcQUhLMeta?.alias || [],
    redirect: index7iSGlcQUhLMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/something-went-wrong/index.vue").then(m => m.default || m)
  },
  {
    name: indexMQS45LvcvmMeta?.name ?? "users",
    path: indexMQS45LvcvmMeta?.path ?? "/users",
    meta: indexMQS45LvcvmMeta || {},
    alias: indexMQS45LvcvmMeta?.alias || [],
    redirect: indexMQS45LvcvmMeta?.redirect || undefined,
    component: () => import("/home/gitlab-runner/builds/RvHxV5WU/0/insurance/frontend/.app/pages/users/index.vue").then(m => m.default || m)
  }
]